<template>
  <div class="product-edit">
    <el-card class="box-card">
      <div slot="header">
        <div class="title fz-18">发布源码</div>
      </div>
      <div class="sctp-bg-white sctp-mar-b10">
        <el-tabs :before-leave="tableChange" v-model="addProductTab" type="card">
          <el-tab-pane label="基本信息" lazy name="1">
            <div class="source_code_information">
              <el-form ref="goodsForm" :model="goodsForm" :rules="goodsFormRules"
                       class="goodsForm" label-width="180px">
                <input type="hidden" v-model="goodsId"/>
                <el-form-item label="源码类型" prop="codetypeid">
                  <el-select v-model="goodsForm.codetypeid" placeholder="请选择源码类型">
                    <el-option
                      v-for="item in sourceTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="源码分类">
                  <el-cascader
                    style="width: 380px;"
                    v-model="goodsForm.typeValue"
                    :options="typeOptions"
                    @change="onTypeCascaderChange"></el-cascader>
                </el-form-item>
                <el-form-item label="商品名称" prop="name">
                  <el-input v-model="goodsForm.name" @change="trimflag()" maxlength="40"></el-input>
                </el-form-item>
                <el-form-item label="源码用途" prop="useflag">
                  <el-radio-group v-model="goodsForm.useflag">
                    <el-radio :label="1">仅供学习</el-radio>
                    <el-radio :label="2">商业用途</el-radio>
                    <el-radio :label="3">完善代码后可商用</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="商品主图" prop="logo">
                  <el-upload
                    :http-request="fileUploadFunction"
                    :data="{...uploadEnum.SOURCE_CODE}"
                    action=""
                    :show-file-list="false"
                    :on-error="uploadConfig.uploadFileError"
                    :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'logo'})"
                    :on-success="uploadSuccess">
                    <el-image v-if="picture.logo" style="width: 80px; height: 80px"
                              :src="picture.logo"
                              fit="contain"></el-image>
                    <el-button v-else class="sctp-square" size="small" type="primary">选取文件
                    </el-button>
                    <div slot="tip" class="el-upload__tip">注：只能上传jpg/png/bmp文件，文件大小1M内，建议格式300X300px
                    </div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="主要开发语言" prop="language">
                  <el-select v-model="goodsForm.language" placeholder="主要开发语言">
                    <template v-if="options && options.language">
                      <template v-for="language in options.language">
                        <el-option :key="language.lngid" :label="language.lngname"
                                   :value="language.lngid"></el-option>
                      </template>
                    </template>
                  </el-select>
                </el-form-item>
                <el-form-item label="开发语言备注">
                  <el-input v-model="goodsForm.languageMark"></el-input>
                </el-form-item>
                <el-form-item label="数据库类型" prop="database">
                  <el-select v-model="goodsForm.database" placeholder="数据库类型">
                    <template v-if="options && options.database">
                      <template v-for="database in options.database">
                        <el-option :key="database.dbtypeid" :label="database.dbname"
                                   :value="database.dbtypeid"></el-option>
                      </template>
                    </template>
                  </el-select>
                </el-form-item>
                <el-form-item label="开发框架">
                  <el-input v-model="goodsForm.framework"></el-input>
                </el-form-item>
                <el-form-item label="演示地址类型" prop="demoflag">
                  <el-radio-group @change="goodsForm.demoAddress = null"
                                  v-model="goodsForm.demoflag">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">网站</el-radio>
                    <el-radio :label="2">二维码</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="goodsForm.demoflag === 1">
                  <el-form-item label="演示地址" prop="demoAddress" >
                    <el-input placeholder="https://sctpweb.ihzsr.cn"
                              v-model="goodsForm.demoAddress"></el-input>
                  </el-form-item>
                </template>
                <template v-else-if="goodsForm.demoflag === 2">
                  <el-form-item label="演示地址"
                                prop="demoAddress"
                                :rules="{required: true, message: '请上传演示地址二维码', trigger: 'blur'}">
                    <el-upload
                      :http-request="fileUploadFunction"
                      :data="{...uploadEnum.SOURCE_CODE}"
                      action=""
                      :show-file-list="false"
                      :on-error="uploadConfig.uploadFileError"
                      :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'demoAddress'})"
                      :on-success="uploadDemoAddressSuccess">
                      <el-image v-if="picture.demoAddress" style="width: 100px; height: 100px"
                                :src="picture.demoAddress" fit="contain"></el-image>
                      <el-button v-else class="sctp-square" size="small" type="primary">选取图片
                      </el-button>
                      <div slot="tip" class="el-upload__tip">只能上传jpg/png/bmp文件，文件大小限制1M</div>
                    </el-upload>
                  </el-form-item>
                </template>
                <el-form-item label="是否需要演示账号" prop="needDemoAccount">
                  <el-radio-group @change="" v-model="goodsForm.needDemoAccount">
                    <el-radio :label="true">需要</el-radio>
                    <el-radio :label="false">不需要</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="goodsForm.needDemoAccount">
                  <el-form-item label="演示账号" prop="demoAccount">
                    <el-input v-model="goodsForm.demoAccount"></el-input>
                  </el-form-item>
                  <el-form-item label="演示密码" prop="demoPassword">
                    <el-input v-model="goodsForm.demoPassword"></el-input>
                  </el-form-item>
                </template>
                <el-form-item class="auto" label="商品功能截图" prop="preview">
                  <el-upload
                    :http-request="fileUploadFunction"
                    :data="{...uploadEnum.SOURCE_CODE}"
                    action=""
                    list-type="picture-card"
                    :multiple="true"
                    ref="uploadFilePreview"
                    :file-list="previewFileList.preview"
                    :class="[{hiddenUpload: goodsForm.preview && goodsForm.preview.length >= previewImgLimit}]"
                    :limit="previewImgLimit"
                    :on-error="uploadConfig.uploadFileError"
                    :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'preview'})"
                    :on-preview="uploadConfig.previewUpload.bind(null, {key: 'preview'})"
                    :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'preview'})"
                    :on-exceed="uploadConfig.fileExceed"
                    :on-remove="uploadConfig.onRemove.bind(null, {key: 'preview'})">
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png/bmp文件, 最大限制1M</div>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <el-carousel indicator-position="none" height="500px;"
                                 :initial-index="previewImgIndex"
                                 :autoplay="false" arrow="always">
                      <el-carousel-item v-for="item in picture.preview" :key="item">
                        <img style="object-fit: contain;" width="100%" height="100%" :src="item"
                             alt="">
                      </el-carousel-item>
                    </el-carousel>
                  </el-dialog>
                </el-form-item>
                <el-form-item label="搜索关键字" prop="keyword">
                  <el-input v-model="goodsForm.keyword" maxlength="100"></el-input>
                  <div class="el-upload__tip">最多五个关键字，使用";"分隔，不要包含空格</div>
                </el-form-item>
                <el-form-item label="当前售价" prop="salePrice">
                  <el-input v-model="goodsForm.salePrice" maxlength="11">
                    <div slot="append">
                      RMB (单位:元)
                    </div>
                  </el-input>
                  <el-tooltip class="el-input-question" effect="dark" content="点击查看佣金比例"
                              placement="top">
                    <i @click="profitRateDialogConfig.showDialog()" class="el-icon-question"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="原价" prop="realPrice">
                  <el-input v-model="goodsForm.realPrice" maxlength="11">
                    <div slot="append">
                      RMB (单位:元)
                    </div>
                  </el-input>
                </el-form-item>
                <el-form-item label="源码大小" prop="codesize">
                  <el-input v-model="goodsForm.codesize">
                    <el-select style="width: 70px;" slot="append" v-model="goodsForm.codeSizeUnit"
                               placeholder="请选择">
                      <el-option
                        v-for="item in codeSizeUnit"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
                <el-form-item label="安装部署指导">
                  <el-radio-group v-model="goodsForm.installFlag">
                    <el-radio :label="1">提供远程安装指导</el-radio>
                    <el-radio :label="2">不提供远程安装指导</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="goodsForm.installFlag === 1">
                  <el-form-item class="auto" label="安装指导价格" prop="installationCosts">
                    <el-input style="width: 300px;" v-model="goodsForm.installationCosts">
                      <div slot="append">
                        RMB (单位:元)
                      </div>
                    </el-input>
                    (注：0元表示免费)
                  </el-form-item>
                </template>
                <el-form-item label="安装部署说明">
                  <el-input type="textarea" :rows="5" maxlength="200"
                            placeholder="安装部署说明" v-model="goodsForm.deployenv">
                  </el-input>
                  <div class="el-input-question" style="right: -100px;">
                    <el-button
                      @click="auxiliaryInputConfig.showDialog"
                      size="small">
                      辅助输入
                    </el-button>
                  </div>
                  <div class="el-upload__tip">注：详细的安装部署说明有利于客户更了解产品，提高成交率</div>
                </el-form-item>
                <el-form-item label="期望交易模式" prop="dealmodel">
                  <el-radio-group v-model="goodsForm.dealmodel">
                    <el-radio :label="1">私下交易</el-radio>
                    <el-radio :label="2">担保交易</el-radio>
                    <el-radio :label="3">两者皆可</el-radio>
                  </el-radio-group>
                  <div class="el-upload__tip">备注：实际交易模式可由双方协商</div>
                </el-form-item>
                <el-form-item class="auto" label="支持发货形式" prop="saleType">
                  <el-checkbox-group v-model="goodsForm.saleType">
                    <el-checkbox label="2">(自动)网盘下载</el-checkbox>
                    <el-checkbox label="3">(自动)网站直接下载</el-checkbox>
                    <el-checkbox label="1">(人工)邮件发送</el-checkbox>
                    <el-checkbox label="4">(手动，需要有实物)物流邮寄</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <template v-if="goodsForm.saleType.includes('2')">
                  <el-form-item label="网盘类型" prop="netdiscName"
                                :rules="{required: true, message: '请选择网盘类型', trigger: ['blur', 'change']}">
                    <el-select v-model="goodsForm.netdiscName" placeholder="选择网盘类型">
                      <template v-if="options && options.netdiscName">
                        <template v-for="netdisc in options.netdiscName">
                          <el-option :key="netdisc.id" :label="netdisc.dropdownvalue"
                                     :value="netdisc.dropdownvalue"></el-option>
                        </template>
                      </template>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="网盘地址" prop="netdiscUrl"
                    :rules="{required: true, message: '请输入网盘地址', trigger: ['blur', 'change']}">
                    <el-input v-model="goodsForm.netdiscUrl" @blur="blur6"></el-input>
                  </el-form-item>
                  <el-form-item label="网盘密码">
                    <el-input v-model="goodsForm.netdiscPwd" @blur="blur14" maxlength="20"></el-input>
                  </el-form-item>
                  <el-form-item label="解压密码">
                    <el-input v-model="goodsForm.netdiscUnzipPwd" @blur="blur15" maxlength="20"></el-input>
                  </el-form-item>
                </template>
                <template v-if="goodsForm.saleType.includes('3')">
                  <el-form-item
                    label="源码" prop="code">
                    <el-upload
                      :http-request="fileUploadFunction"
                      :data="{...uploadEnum.SOURCE_CODE}"
                      action=""
                      ref="uploadFile"
                      :on-error="uploadConfig.uploadFileError"
                      :before-upload="beforeUploadCode"
                      :on-remove="onRemoveCode"
                      :on-success="uploadSuccessCode"
                      :file-list="previewFileList.code"
                    >
                      <el-button class="sctp-square" size="small" type="primary">点击上传</el-button>
                      <div slot="tip" class="el-upload__tip">文件最大支持50M</div>
                    </el-upload>
                  </el-form-item>
                </template>
                <el-form-item label="库存数量" prop="stock">
                  <el-input :maxlength="5" v-model.number="goodsForm.stock">
                    <div slot="append">最多10000</div>
                  </el-input>
                </el-form-item>
                <el-form-item class="auto" label="商品简介">
                  <el-input type="textarea" :rows="5" maxlength="200" placeholder="介绍一下项目..."
                            v-model="goodsForm.remark"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button size="small" @click="onTemporaryStorageClick">暂存</el-button>
                  <el-button size="small" type="primary" @click="setTab('2')">下一步</el-button>
                  <el-button size="small" @click="$router.back()">返回</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="源码片段" lazy name="2">
            <div class="sctp-flex sctp-flex-sb sctp-pad-tb15">
              <div>
                <el-button @click="sourceCodeDialogConfig.open" class="sctp-square" size="small"
                           type="primary">添加代码片段内容
                </el-button>
              </div>
            </div>
            <div class="el-upload__tip sctp-mar-b10">
              注：请复制您产品源码中的部分代码片段内容，以便让用户更了解，更有利于成交。源码片段至少一页，否则审核将不通过。源码片段必须是交付给客户源码中的部分，否则引起纠纷自己承担责任。
            </div>
            <el-dialog
              title="提示"
              :visible.sync="sourceCodeDialogConfig.show"
              width="30%">
              <el-form ref="sourceCodeTitle" :model="sourceCodeDialogConfig.form"
                       :rules="sourceCodeDialogConfig.formRules">
                <div class="sctp-pad-b10">
                  请输入代码片段描述
                </div>
                <el-form-item prop="title">
                  <el-input v-model="sourceCodeDialogConfig.form.title"></el-input>
                </el-form-item>
                <div class="fz-12">参考代码片段描述:</div>
                <div class="sctp-pad-tb5">
                  <template v-for="item in options && options.codePagesDescription || []">
                    <el-button
                      :key="item" size="mini"
                      style="margin-left: unset!important;"
                      class="sctp-mar-r5 sctp-mar-b5"
                      @click="sourceCodeDialogConfig.onQuickValueClick(item)">{{ item }}
                    </el-button>
                  </template>
                </div>
                <div class="sctp-flex sctp-flex-sb">
                  <div></div>
                  <div>
                    <el-button @click="sourceCodeDialogConfig.cancel" size="mini">取消</el-button>
                    <el-button @click="sourceCodeDialogConfig.confirm('sourceCodeTitle')"
                               size="mini" type="primary">
                      确定
                    </el-button>
                  </div>
                </div>
              </el-form>
            </el-dialog>
            <template v-if="sourceCode && sourceCode.length > 0">
              <el-tabs v-model="editableTabsValue" @tab-remove="removeSourceCodeTab" type="card"
                       closable>
                <el-tab-pane
                  v-for="(item) in sourceCode"
                  lazy
                  :key="item.name"
                  :name="item.name"
                  :label="item.title"
                >
                  <ckeditor v-model="item.content" :config="sourceCodeCKEditorConfig"></ckeditor>
                </el-tab-pane>
              </el-tabs>
            </template>
            <div class="sctp-mar-tb15">
              <el-button size="small" @click="onTemporaryStorageClick">暂存</el-button>
              <el-button class="sctp-square" size="small" @click="setTab('1')">上一步</el-button>
              <el-button class="sctp-square" size="small" type="primary" @click="setTab('3')">下一步
              </el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="版权信息" name="3">
            <el-form ref="goodsCopyRightForm" :model="goodsForm" :rules="copyrightFormRules"
                     label-width="180px">
              <el-form-item label="是否有版权证书">
                <el-radio-group v-model="goodsForm.copyrightFlag">
                  <el-radio :label="1">有</el-radio>
                  <el-radio :label="0">没有</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="goodsForm.copyrightFlag === 1">
                <el-form-item label="版权证书" prop="copyrightImg"
                              :rules="[
                                          {required: true, message: '请上传版权图片', trigger: ['blur']}
                                        ]"
                >
                  <el-upload
                    :http-request="fileUploadFunction"
                    :class="['A4', {hiddenUpload: goodsForm.copyrightImg}]"
                    :data="{...uploadEnum.SOURCE_CODE}"
                    action=""
                    ref="uploadFile"
                    :file-list="previewFileList.copyrightImg"
                    list-type="picture-card"
                    :on-error="uploadConfig.uploadFileError"
                    :on-success="uploadConfig.uploadSuccess.bind(null, {key: 'copyrightImg'})"
                    :before-upload="uploadConfig.beforeUpload.bind(null, {key: 'copyrightImg'})"
                    :on-remove="uploadConfig.onRemove.bind(null,  {key: 'copyrightImg'})">
                    <i class="el-icon-plus"></i>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png/bmp文件，文件最大允许1M，建议A4纸比例</div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="版权是否转让">
                  <el-radio-group v-model="goodsForm.transfer_copyright">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                  <div class="el-upload__tip" v-if="goodsForm.transfer_copyright">
                    注：版权转让需双方线下在版权机构完成版权转让手续，也可以委托第三方代办
                  </div>
                </el-form-item>
                <template v-if="goodsForm.transfer_copyright === true">
                  <el-form-item label="版权费及转让手续费" prop="copyrightFee">
                    <el-input style="width: 400px;" v-model="goodsForm.copyrightFee">
                      <div slot="append">
                        RMB (单位:元)
                      </div>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="价格是否包含版权费">
                    <el-radio-group v-model="goodsForm.contain_copyright_price">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                    <div class="el-upload__tip">注：转让版权仅允许出售一次</div>
                  </el-form-item>
                </template>
              </template>
              <el-form-item>
                <el-button size="small" @click="onTemporaryStorageClick">暂存</el-button>
                <el-button class="sctp-square" size="small" @click="setTab('2')">上一步</el-button>
                <el-button class="sctp-square" size="small" type="primary" @click="setTab('4')">
                  下一步
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="商品介绍" lazy name="4">
            <ckeditor v-model="goodsForm.introduce"></ckeditor>
            <div class="sctp-mar-tb15">
              <el-button size="small" @click="onTemporaryStorageClick">暂存</el-button>
              <el-button class="sctp-square" size="small" @click="setTab('3')">上一步</el-button>
              <el-button class="sctp-square" size="small" type="primary" @click="onSubmit">提交
              </el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
    <el-dialog
      center
      title="佣金比例"
      :visible.sync="profitRateDialogConfig.showFlag"
    >
      <profit-rate></profit-rate>
    </el-dialog>
    <el-dialog
      center
      title="辅助输入"
      width="600px"
      :visible.sync="auxiliaryInputConfig.show"
    >
      <el-form
        size="mini"
        ref="auxiliaryInputForm"
        :model="auxiliaryInputConfig.form"
        :rules="auxiliaryInputConfig.formRules"
        class="auxiliaryInputForm">
        <el-form-item prop="deploymentEnvironment">
          支持部署环境：
          <el-checkbox-group v-model="auxiliaryInputConfig.form.deploymentEnvironment">
            <el-checkbox label="Window 环境"></el-checkbox>
            <el-checkbox label="Linux 环境"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          应用服务器数量：
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.serverNumber" @blur="blur1"></el-input>
          台
        </el-form-item>
        <el-form-item>
          应用服务器最低配置要求：
          内存
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.applicationServerRAM" @blur="blur7"></el-input>
          G,
          CPU
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.applicationServerCPU" @blur="blur3" ></el-input>
          核,
          硬盘
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.applicationServerHardDisk" @blur="blur8"></el-input>
          G,
        </el-form-item>
        <el-form-item>
          数据库服务器数量：
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.dbServerNumber" @blur="blur4"></el-input>
          台,
        </el-form-item>
        <el-form-item>
          数据库服务器最低配置要求：
          内存
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.dbServerRAM" @blur="blur9"></el-input>
          G,
          CPU
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.dbServerCPU" @blur="blur5"></el-input>
          核,
          硬盘
          <el-input class="inner-input"
                    v-model="auxiliaryInputConfig.form.dbServerHardDisk" @blur="blur10"></el-input>
          G,
        </el-form-item>
        <el-form-item>
          数据库支持类型：
          <el-input style="width: calc(100% - 130px)"
                    v-model="auxiliaryInputConfig.form.dbType" @blur="blur11" maxlength="40"></el-input>
        </el-form-item>
        <el-form-item prop="dbVersion">
          数据库版本要求：
          <el-input style="width: calc(100% - 130px)"
                    v-model="auxiliaryInputConfig.form.dbVersion" @blur="blur12" maxlength="40"></el-input>
        </el-form-item>
        <el-form-item prop="vmSupport">
          虚拟机部署：
          <el-radio-group v-model="auxiliaryInputConfig.form.vmSupport" >
            <el-radio label="支持"></el-radio>
            <el-radio label="不支持"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="webContainer">
          支持Web容器：
          <el-input style="width: calc(100% - 110px)"
                    v-model="auxiliaryInputConfig.form.webContainer" @blur="blur13" maxlength="40"></el-input>
        </el-form-item>
        <div class="sctp-tc">
          <el-button @click="auxiliaryInputConfig.show = false" size="small">取消</el-button>
          <el-button @click="auxiliaryInputConfig.onSubmitClick" size="small" type="primary">确认
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import validator from '../../../../resources/js/async-validator'
import uploadEnum from "@/resources/js/uploadenum";
import {sourceCode} from "@/apis/index";

const config = require('../../../../resources/js/config');

export default {
  components: {
    ckeditor: () => import('@CMP/ckeditor/Index'),
    ProfitRate: () => import('@PAGE/common/ProfitRate'),
  },
  data: function () {
    return {
      uploadEnum,
      category: null,
      goodsId: null,
      addProductTab: '1',
      picture: {
        logo: null,
        copyrightImg: null,
        preview: [],
        demoAddress: null
      },
      previewFileList: {
        preview: [],
        code: [],
      },
      goodsForm: {
        typeValue: [],
        typeid: null,
        subtypeid: null,
        name: null,
        logo: null,
        salePrice: null,
        realPrice: null,
        saleType: [],
        copyrightFlag: 0,
        copyrightImg: null,
        language: null,
        languageMark: null,
        database: null,
        framework: null,
        deployenv: null,
        codesize: null,
        demoflag: null, // 演示地址类型
        needDemoAccount: false,
        demoAccount: null,
        demoPassword: null,
        demoAddress: null,
        introduce: null, // 商品介绍
        keyword: null,
        preview: [], // 预览图
        remark: null, // 商品简介
        stock: null, // 库存
        netdiscName: null, // 网盘类型
        netdiscUrl: null, // 网盘地址
        netdiscPwd: null, // 网盘密码
        netdiscUnzipPwd: null, // 网盘解压密码
        code: null, // 源码路径
        sourceCode: null, // 源码内容
        codetypeid: null, // 源码分类
        dealmodel: null, // 交易模式
        codeSizeUnit: 1, // 源码大小单位
        copyrightFee: null,
        transfer_copyright: null,
        contain_copyright_price: null,
        useflag: 1,
        installFlag: null, // 安装指导
        installationCosts: null, // 安装指导费用
      },
      goodsFormRules: {
        typeValue: [
          {
            required: true,
            message: '源码分类必选',
            trigger: ['blur', 'change']
          }
        ],

        name: [
          {
            required: true,
            message: '商品名必填',
            trigger: ['blur', 'change']
          }
        ],
        useflag: [
          {
            required: true,
            message: '源码用途必选',
            trigger: ['blur', 'change']
          }
        ],
        logo: [
          {
            required: true,
            message: '请上传主图',
            trigger: ['blur', 'change']
          }
        ],
        language: [
          {
            required: true,
            message: '主要开发语言不能为空',
            trigger: ['blur', 'change']
          }
        ],
        database: [
          {
            required: true,
            message: '数据库类型不能为空',
            trigger: ['blur', 'change']
          }
        ],
        needDemoAccount: [
          {
            required: true,
            message: '演示账号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        demoPassword: [
          {
            required: true,
            message: '演示密码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        demoAddress: [
          {required: true, message: '演示密码不能为空', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              } else {
                const valid = /^((https?):\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
                const va2 = /^[^\s]*$/;

                if (valid.test(value) && va2.test(value)) {
                  callback()
                } else {
                  callback(new Error(rule.message))
                }
              }
            },
            message: '地址不合法',
            trigger: ['change', 'blur']
          }
        ],
        preview: [
          {
            required: true,
            message: '至少上传一张功能截图',
            trigger: ['blur', 'change']
          }
        ],
        keyword: [
          {
            validator: (rule, value, callback) => {
              const reg = /^([\w\d\u4e00-\u9fa5]+;?){1,5}$/;
              if (!value) {
                callback()
              } else {
                value = value + ';';
                if (reg.test(value)) {
                  callback()
                } else {
                  callback(new Error('关键字不符合规则'))
                }
              }
            }
          }
        ],
        salePrice: [
          {
            required: true,
            message: '售价不能为空',
            trigger: ['blur', 'change']
          },
          {
            validator: validator.validMoney,
            message: '金额数值不合法'
          },
          {
            validator: (rule, value, callback) => {
              let {realPrice} = this.goodsForm;
              realPrice = parseFloat(realPrice);
              value = parseFloat(value);
              if ((realPrice || realPrice === 0) && realPrice < value) {
                callback(new Error(rule.message))
              } else {
                callback()
              }
            },
            message: '售价不能大于原价'
          },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const price = parseFloat(value);
                if (price < 10.00 || price > 1000000.00) {
                  callback(new Error('金额范围10.00 ~ 1000000.00'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            }
          }
        ],
        installationCosts: [
          {required: true, message: '指导价格', trigger: ['blur', 'change']},
          {validator: validator.validMoney, message: '金额数值不合法'},
        ],
        realPrice: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                validator.validMoney(rule, value, callback)
              } else {
                callback()
              }
            },
            message: '金额数值不合法'
          },
          {
            validator: (rule, value, callback) => {
              let {salePrice} = this.goodsForm;
              salePrice = parseFloat(salePrice);
              value = parseFloat(value);
              if (value && salePrice && salePrice > value) {
                callback(new Error(rule.message))
              } else {
                callback()
              }
            },
            message: '原价不能小于售价'
          },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const price = parseFloat(value);
                if (price < 10.00 || price > 1000000.00) {
                  callback(new Error('金额范围10.00 ~ 1000000.00'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            }
          }
        ],
        dealmodel: [
          {
            required: true,
            message: '交易模式必填',
            trigger: 'change'
          }
        ],
        saleType: [
          {
            required: true,
            message: '发货形式必填',
            trigger: 'change'
          }
        ],
        demoflag: [
          {
            required: true,
            message: '请选择地址类型',
            trigger: 'change'
          }
        ],
        codesize: [
          {
            validator: (rule, value, callback) => {
              if (value) {
                validator.validNum(rule, value, callback)
              } else {
                callback()
              }
            },
            message: '只能为数字类型',
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              if (value <0) {
                callback(new Error("源码大小不能为负数"))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {
            validator: (rule, value, callback) => {
              if (!this.goodsForm.code) {
                callback(new Error(rule.message))
              } else {
                callback()
              }
            },
            message: '请上传源码'
          }
        ],
        stock: [
          {
            required: true,
            message: '库存不能为空',
            trigger: ['blur', 'change'],
          },
          {
            validator: validator.validInteger,
            flag: '+',
            trigger: ['blur', 'change'],
            message: '库存数值不合法'
          },
          {
            validator: (rule, value, callback) => {
              if (value > 10000) {
                callback('最大10000');
              } else if (value <= 0) {
                callback('库存不能小于1');
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          },
        ],
        codetypeid: [
          {
            required: true,
            message: '源码分类不能为空'
          }
        ]
      },
      copyrightFormRules: {
        copyrightFee: [
          {
            required: true,
            message: '价格不能为空',
            trigger: ['blur', 'change']
          },
          {
            validator: validator.validMoney,
            message: '金额数值不合法',
            trigger: ['blur', 'change']
          },
        ],
      },
      options: null,
      dialogVisible: false,
      profitRateDialogConfig: {
        showFlag: false,
        showDialog: () => {
          this.profitRateDialogConfig.showFlag = true;
        },
      },
      previewImgIndex: 0,
      previewImgLimit: 9,
      tableChange: (activeName, oldActiveName) => {
        this.changeTab(oldActiveName, activeName)
      },
      sourceCode: [],
      sourceCodeCKEditorConfig: {
        toolbar: []
      },
      sourceCodeTabIndex: 0,
      editableTabsValue: null,
      sourceCodeDialogConfig: {
        show: false,
        open: () => {
          this.sourceCodeDialogConfig.show = true;
        },
        form: {
          title: null,
        },
        formRules: {
          title: [
            {required: true, message: '不能为空', trigger: ['change', 'blur']},
            {
              validator: (rule, value, callback) => {
                if (/^.{1,50}$/.test(value)) {
                  if (this.sourceCode.filter(item => item.title === value).length > 0) {
                    callback(new Error('描述已存在'));
                  } else {
                    callback();
                  }
                } else {
                  callback(new Error('长度限制1-50字符之间'));
                }
              },
            }
          ]
        },
        cancel: () => {
          this.sourceCodeDialogConfig.show = false;
        },
        quickValue: [
          '首页源码', '登录功能源码', '支付功能源码', '基本配置文件内容'
        ],
        onQuickValueClick: (value) => {
          this.sourceCodeDialogConfig.form.title = value;
        },
        confirm: (formName) => {
          this.$refs[formName].validate().then(() => {
            const newTabName = ++this.sourceCodeTabIndex + '';
            let value = this.sourceCodeDialogConfig.form.title;
            value = value.trim();
            this.sourceCode.push({
              title: value,
              content: '',
              name: newTabName
            });
            this.sourceCodeDialogConfig.show = false;
            this.$refs[formName].resetFields();
            this.editableTabsValue = newTabName;
          });
        },
      },
      uploadConfig: {
        uploadSuccess: ({key}, response, file, fileList) => {
          console.log(response)
          if(response.code) {
            const {src} = response;
            switch (key) {
              case 'copyrightImg':
                this.goodsForm.copyrightImg = src;
                break;
              case 'preview':
                this.goodsForm.preview.push(src);
                this.picture.preview.push(src);
                this.$message.success('上传文件成功')
                this.$refs.goodsForm.validateField('preview')
                break;
            }
            this.$message.success('文件上传成功！');
          }else {
            let uid = file.uid
            if (key == 'copyrightImg'){
              let idx = this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === uid)
              this.$refs.uploadFile.uploadFiles.splice(idx, 1)
            }else if(key == 'preview'){
              let idx = this.$refs.uploadFilePreview.uploadFiles.findIndex(item => item.uid === uid)
              this.$refs.uploadFilePreview.uploadFiles.splice(idx, 1)
            }
            this.$message.error('上传文件失败')
          }
        },
        onRemove: ({key}, file, fileList) => {
          switch (key) {
            case 'copyrightImg':
              this.goodsForm.copyrightImg = null;
              this.picture.copyrightImg = null;
              break;
            case 'preview':
              if (file.response || file.url) {
                let path = (file.response && file.response.src) || file.url;
                path = path.substring(path.indexOf('/upload/'));
                const index = this.goodsForm.preview.findIndex(item => item === path);
                this.goodsForm.preview.splice(index, 1);
                this.picture.preview.splice(index, 1)
              }
              break;
          }
        },
        previewUpload: ({key}, file) => {
          if (file.response && file.response.src) {
            const path = file.response.src;
            this.previewImgIndex = this.goodsForm.preview.findIndex(item => item === path) || 0
          } else {
            const path = file.url;
            this.previewImgIndex = this.picture.preview.findIndex(item => item === path) || 0
          }
          this.dialogVisible = true
        },
        beforeUpload: ({key}, file) => {
          let result = false;
          let isImage, isLtSize;
          //格式判断
          const uploadType = ['jpg','png','bmp','jpeg'];
          const filetype=file.name.replace(/.+\./,'');
          if(uploadType.indexOf(filetype.toLowerCase())=== -1){
            this.$message.warning({
              message:'请上传后缀为jpg、png、bmp的附件'
            })
            return false;
          }

          switch (key) {
            case 'copyrightImg':
              isImage = file.type.startsWith('image');
              isLtSize = file.size / 1024 / 1024 < 1;
              if (!isImage) {
                this.$message.error('只能上传图片格式!')
              }
              if (!isLtSize) {
                this.$message.error('图片大小不能超过1M!')
              }
              result = isImage && isLtSize;
              break;
            case 'logo':
              isImage = file.type.startsWith('image');
              isLtSize = file.size / 1024  <= 1024;
              console.log(file.size / 1024)
              if (!isImage) {
                this.$message.error('只能上传图片格式!')
              }
              if (!isLtSize) {
                this.$message.error('图片大小不能超过1M!')
              }
              result = isImage && isLtSize;
              break;
            case 'preview':
              isImage = file.type.startsWith('image');
              isLtSize = file.size / 1024 / 1024 < 1;
              if (!isImage) {
                this.$message.error('只能上传图片格式!')
              }
              if (!isLtSize) {
                this.$message.error('图片大小不能超过1M!!')
              }
              result = isImage && isLtSize;
              break;
            case 'demoAddress':
              isImage = file.type.startsWith('image');
              isLtSize = file.size / 1024 / 1024 < 1;
              if (!isImage) {
                this.$message.error('只能上传图片格式!')
              }
              if (!isLtSize) {
                this.$message.error('图片大小不能超过1M!')
              }
              result = isImage && isLtSize;
              break;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed: () => {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError: () => {
          this.$message.error('文件上传失败');
        },
      },
      auxiliaryInputConfig: {
        show: false,
        showDialog: () => {
          this.auxiliaryInputConfig.show = true;
        },
        form: {
          deploymentEnvironment: [],
          serverNumber: null,
          applicationServerRAM: null,
          applicationServerCPU: null,
          applicationServerHardDisk: null,
          dbServerNumber: null,
          dbServerRAM: null,
          dbServerCPU: null,
          dbServerHardDisk: null,
          dbType: null,
          dbVersion: null,
          vmSupport: null,
          webContainer: null,
        },
        formRules: {},
        onSubmitClick: () => {
          let {form} = this.auxiliaryInputConfig;
          let content = `支持部署环境：${form.deploymentEnvironment}
应用服务器数量：${form.serverNumber || ''}台
应用服务器最低配置要求：内存${form.applicationServerRAM || ''}G, CPU${form.applicationServerCPU
          || ''}核，硬盘${form.applicationServerHardDisk || ''}G
数据库服务器数量：${form.dbServerNumber || ''}台
数据库服务器最低配置要求：内存${form.dbServerRAM || ''}G, CPU${form.dbServerCPU || ''}核，硬盘${form.dbServerHardDisk
          || ''}G
数据库支持类型：${form.dbType || ''}
数据库版本要求：${form.dbVersion || ''}
虚拟机部署：${form.vmSupport || ''}
支持Web容器：${form.webContainer || ''}
          `;
          this.goodsForm.deployenv = content;
          this.auxiliaryInputConfig.show = false;
        },
      }
    }
  },
  mounted() {
  },
  methods: {
    trimflag(){
      this.goodsForm.name = this.goodsForm.name.trim()
    },
    blur1(){
      if(!(/^[1-9]\d*$/).test(this.auxiliaryInputConfig.form.serverNumber)&&
        this.auxiliaryInputConfig.form.serverNumber != null)
      {
        this.$alert('应用服务器数量必须为整数')
        this.auxiliaryInputConfig.form.serverNumber=1
      }
    },
    // blur2(){
    //   if(!(/^[1-9]\d*$/.test(this.auxiliaryInputConfig.form.applicationServerRAM)))
    //   {
    //     this.$alert('应用服务器内存必须为整数')
    //     this.form.applicationServerRAM=1
    //   }
    // },
    blur3(){
      if(!(/^[1-9]\d*$/).test(this.auxiliaryInputConfig.form.applicationServerCPU)&&
        this.auxiliaryInputConfig.form.applicationServerCPU != null)
      {
        this.$alert('应用服务器最低配置CPU必须为整数')
        this.auxiliaryInputConfig.form.applicationServerCPU=1
      }
    },
    blur4(){
      if(!(/^[1-9]\d*$/).test(this.auxiliaryInputConfig.form.dbServerNumber)&&
        this.auxiliaryInputConfig.form.dbServerNumber != null)
      {
        this.$alert('数据库服务器数量必须为整数')
        this.auxiliaryInputConfig.form.dbServerNumber=1
      }
    },
    blur5(){
      if(!(/^[1-9]\d*$/).test(this.auxiliaryInputConfig.form.dbServerCPU)&&
        this.auxiliaryInputConfig.form.dbServerCPU != null)
      {
        this.$alert('数据库服务器最低配置CPU必须为整数')
        this.auxiliaryInputConfig.form.dbServerCPU=1
      }
    },
    blur6(){
      if(!(/^[hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/.test(this.goodsForm.netdiscUrl)))
      {
        this.$alert('网盘地址错误，http或https开头加域名地址');
        this.goodsForm.netdiscUrl=null;
      }
    },
    blur7(){
      if(!(/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(this.auxiliaryInputConfig.form.applicationServerRAM)))
      {
        this.$alert('应用服务器最低配置内存必须为整数或者小数');
        this.auxiliaryInputConfig.form.applicationServerRAM=null;
      }
    },
    blur8(){
      if(!(/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(this.auxiliaryInputConfig.form.applicationServerHardDisk)))
      {
        this.$alert('应用服务器最低配置硬盘必须为整数或者小数');
        this.auxiliaryInputConfig.form.applicationServerHardDisk=null;
      }
    },
    blur9(){
      if(!(/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(this.auxiliaryInputConfig.form.dbServerRAM)))
      {
        this.$alert('数据库服务器最低配置内存必须为整数或者小数');
        this.auxiliaryInputConfig.form.dbServerRAM=null;
      }
    },
    blur10(){
      if(!(/^[0-9]+([.]{1}[0-9]+){0,1}$/.test(this.auxiliaryInputConfig.form.dbServerHardDisk)))
      {
        this.$alert('数据库服务器最低配置硬盘必须为整数或者小数');
        this.auxiliaryInputConfig.form.dbServerHardDisk=null;
      }
    },
    blur11(){
      console.log(1)
      if(!(/^[\u4E00-\u9FA5A-Za-z0-9_]+$/.test( this.auxiliaryInputConfig.form.dbType)))
      {
        this.$alert('数据库支持类型不能含有特殊符号');
        this.auxiliaryInputConfig.form.dbType=null;
      }
    },
    blur12(){
      console.log(1)
      if(!(/^[\u4E00-\u9FA5A-Za-z0-9_]+$/.test( this.auxiliaryInputConfig.form.dbVersion)))
      {
        this.$alert('数据库版本要求不能含有特殊符号');
        this.auxiliaryInputConfig.form.dbVersion=null;
      }
    },
    blur13(){
      console.log(1)
      if(!(/^[\u4E00-\u9FA5A-Za-z0-9_]+$/.test( this.auxiliaryInputConfig.form.webContainer)))
      {
        this.$alert('支持版本容器不能含有特殊符号');
        this.auxiliaryInputConfig.form.webContainer=null;
      }
    },
    blur14(){
      if(!(/^\w{1,20}$/.test(this.goodsForm.netdiscPwd)))
      {
        this.$alert('网盘密码格式错误');
        this.goodsForm.netdiscPwd=null;
      }
    },
    blur15(){
      if(!(/^\w{1,20}$/.test(this.goodsForm.netdiscUnzipPwd)))
      {
        this.$alert('网盘解压密码格式错误');
        this.goodsForm.netdiscUnzipPwd=null;
      }
    },



    onTypeCascaderChange(value) {
      this.goodsForm.typeid = value[0];
      this.goodsForm.subtypeid = value[1];
    },
    onRemoveCode() {
      this.goodsForm.code = null
    },
    setTab(tag) {
      this.addProductTab = tag
    },
    changeTab(currentTab, targetTab) {
      const currentTabInt = parseInt(currentTab);
      const targetTabInt = parseInt(targetTab);
      const check1 = () => {
        return this.$refs.goodsForm.validate()
        .catch(() => {
          this.$message.warning('请完成表单');
          return Promise.reject('1')
        })
      };
      const check3 = () => {
        return this.$refs.goodsCopyRightForm.validate().catch(() => {
          this.$message.warning('请完成表单');
          return Promise.reject('3')
        })
      };
      if (targetTabInt < currentTabInt) {
        // 如果是上一步，直接通过
        this.addProductTab = targetTab
      } else {
        let checkArray = null;
        if (targetTabInt > 3) {
          checkArray = () => {
            return [check1(), check3()]
          }
        } else if (targetTabInt > 1) {
          checkArray = () => {
            return [check1()]
          }
        } else {
          this.addProductTab = targetTab
        }
        if (checkArray) {
          return Promise.all(checkArray()).then(res => {
            this.addProductTab = targetTab
          }).catch((err) => {
            this.addProductTab = err;
            return Promise.reject()
          })
        }
      }
    },
    /**
     * 暂存按钮点击触发
     */
    onTemporaryStorageClick() {
      const {goodsForm: formData, goodsFormRules} = this;
      let checkField = [];
      let exclude = ['typeValue'];
      Object.keys(formData).forEach(key => {
        if (exclude.includes(key)) {
          return;
        }
        let value = formData[key];
        let needCheck = false;
      })
      this.submitFormData(true);
      // if (checkField.length === 0) {
      //   this.submitFormData(true);
      // } else {
      //   let hasError;
      //   let counter = 0;
      //   this.submitFormData(true);
        // this.$refs['goodsForm'].validateField(checkField, (errorMessage => {
        //   ++counter;
        //   if (errorMessage) {
        //     if (!hasError) {
        //       hasError = true;
        //       this.$message.warning(errorMessage);
        //     }
        //   } else if (!hasError && counter === checkField.length) {
        //     this.submitFormData(true);
        //   }
        // }));
      // }
    },
    /**
     * 提交表单数据.
     */
    submitFormData(temp = false) {
      const formData = this.goodsForm;
      this.$request.post({
        reqcode: '1012',
        reqdesc: '编辑商品',
        goodsid: this.goodsId,
        goodsname: formData.name,
        goodslogo: formData.logo,
        realprice: formData.realPrice,
        saleprice: formData.salePrice,
        saletype: formData.saleType,
        copyrightflag: formData.copyrightFlag,
        copyright_imgurl: formData.copyrightImg,
        lngid: formData.language,
        lngmemo: formData.languageMark,
        dbtypeid: formData.database,
        framework: formData.framework,
        deployenv: formData.deployenv,
        codesize: formData.codesize * formData.codeSizeUnit,
        typeid: formData.typeid,
        subtypeid: formData.subtypeid,
        demourl: formData.demoAddress,
        introduce: formData.introduce,
        keyword: formData.keyword,
        screenshot: formData.preview.join(','),
        remark: formData.remark,
        stock: formData.stock,
        netdiscName: formData.netdiscName,
        netdiscUrl: formData.netdiscUrl,
        netdiscPwd: formData.netdiscPwd,
        netdiscUnzipPwd: formData.netdiscUnzipPwd,
        codePath: formData.code,
        codetypeid: formData.codetypeid,
        dealmodel: formData.dealmodel,
        sourceCode: this.sourceCode, // 源码
        userId: this.user.userId,
        demoflag: formData.demoflag, // 演示地址
        temporary_storage: temp, // 暂存
        contain_copyright_price: formData.contain_copyright_price,
        transfer_copyright: formData.transfer_copyright,
        useflag: formData.useflag,
        isneedaccount: formData.needDemoAccount,
        demoaccount: formData.demoAccount,
        demopassword: formData.demoPassword,
        copyrightFee: formData.copyrightFee,
        installFlag: formData.installFlag,
        installationCosts: formData.installationCosts,
      }).then(res => {
        const {goodsId} = res;
        this.goodsId = goodsId;
        this.$message.success('保存成功');
        this.$router.push('/shop/sale/sourcecode')
      })
    },
    /** 最后一步检验表单内容之后提交 **/
    onSubmit() {
      this.submitFormData()
    },
    beforeUploadCode(file) {
      const isLtSize = file.size / 1024 / 1024 < 50;
      if (!isLtSize) {
        this.$message.error('源码大小不能超过50MB!')
      }
      return isLtSize && this.$message('文件正在上传') && true
    },
    uploadSuccess(res) {
      if (res.code) {
        const {src,preview} = res;

        this.goodsForm.logo = src;
        this.picture.logo = preview;
        this.$message.success('上传文件成功')
      } else {
        this.$message.error('上传文件失败')
      }
      this.$refs.goodsForm.validateField('logo')
    },
    uploadDemoAddressSuccess(res) {
      if (res.code) {
        const {src} = res;
        this.goodsForm.demoAddress = src;
        this.picture.demoAddress = src;
        this.$message.success('上传文件成功')
      } else {
        this.$message.error('上传文件失败')
      }
      this.$refs.goodsForm.validateField('demoAddress')
    },
    uploadSuccessCode(res, file, fileList) {
      if (res.code) {
        const {src} = res;
        this.goodsForm.code = src;
        fileList.splice(0, fileList.length);
        fileList.push(file);
        this.$message.success('上传文件成功')
      } else {
        this.$message.error('上传文件失败')
      }
      this.$refs.goodsForm.validateField('code');
    },
    getOptions() {
      sourceCode.condition()
      .then(res => {
        console.log("****************",res)
        this.options = res.data;
      })
    },
    getGoodsDetail() {
      if (!this.goodsId) {
        return;
      }
      sourceCode.editDetail({
        productId: this.goodsId,
        userId: this.user.userId
      }).then(res => {

        const {data, sourceCode = []} = res;
        if (data) {
          console.log("----------------------->",data)
          this.goodsForm.name = data.goodsname || null;
          this.goodsForm.logo = data.goodslogo || null;
          this.goodsForm.salePrice = data.saleprice;
          this.goodsForm.realPrice = data.realprice;
          this.goodsForm.saleType = data.saletype ? data.saletype.split(',') : [];
          this.goodsForm.copyrightFlag = data.copyrightflag || 0;
          this.goodsForm.copyrightImg = data.copyright_imgurl || null;
          this.goodsForm.language = data.lngid || null;
          this.goodsForm.languageMark = data.lngmemo || null;
          this.goodsForm.database = data.dbtypeid || null;
          this.goodsForm.framework = data.framework || null;
          this.goodsForm.deployenv = data.deployenv || null;
          this.goodsForm.codesize = data.codesize || (data.codesize === 0 ? 0 : null);
          this.goodsForm.demoAddress = data.demourl || null;
          this.goodsForm.introduce = data.introduce || null;
          this.goodsForm.keyword = data.keyword || null;
          this.goodsForm.stock = data.stock;
          this.goodsForm.remark = data.remark || null;
          this.goodsForm.netdiscName = data.netdiscname || null;
          this.goodsForm.netdiscUrl = data.netdiscUrl || null;
          this.goodsForm.netdiscPwd = data.netdiscPwd || null;
          this.goodsForm.netdiscUnzipPwd = data.netdiscUnzipPwd || null;
          this.goodsForm.code = data.codePath || null;
          this.goodsForm.sourceCode = data.sourceCode || null;
          this.goodsForm.codetypeid = data.codetypeid || null;
          this.goodsForm.dealmodel = data.dealmodel || null;
          this.goodsForm.transfer_copyright = data.transfer_copyright;
          this.goodsForm.contain_copyright_price = data.contain_copyright_price;
          this.goodsForm.useflag = data.useflag;
          this.goodsForm.needDemoAccount = data.isneedaccount;
          this.goodsForm.demoAccount = data.demoaccount;
          this.goodsForm.demoPassword = data.demopassword;
          this.goodsForm.typeid = data.typeid;
          this.goodsForm.subtypeid = data.subtypeid;
          this.goodsForm.copyrightFee = data.copyrightFee;
          this.goodsForm.installFlag = data.install_flag;
          this.goodsForm.installationCosts = data.installation_costs;
          this.goodsForm.typeValue = [data.typeid, data.subtypeid];
          this.$nextTick(() => {
            this.goodsForm.demoflag = data.demoflag;
          });
          const imgArr = data.screenshot ? data.screenshot.split(',') : [];
          this.goodsForm.preview = imgArr;
          const picture = {};
          picture.preview = imgArr.map(item => item);
          console.log(picture.preview)
          picture.logo = data.goodslogo ? data.goodslogo : null;
          picture.copyrightImg = data.copyright_imgurl ? data.copyright_imgurl
            : null;
          if (data.demoflag === 2) {
            picture.demoAddress = data.demourl ? data.demourl : null
          } else {
            picture.demoAddress = null
          }
          this.picture = picture;
          let fileList = function (urlArray) {
            return urlArray.filter(item => item !== null).map(item => {
              return {
                url: item,
                name: item.substring(item.lastIndexOf('/') + 1),
              }
            })
          }
          this.previewFileList = {
            preview: fileList(picture.preview),
            code: fileList([data.codePath]),
            copyrightImg: fileList([picture.copyrightImg]),
          };
        }
        this.sourceCode = sourceCode.map(item => {
          const newTabName = ++this.sourceCodeTabIndex + '';
          return {
            id: item.id,
            content: item.content,
            title: item.title,
            name: newTabName
          }
        });
        if (this.sourceCode.length > 0) {
          this.editableTabsValue = this.sourceCode[0].name
        }
      })
    },
    addSourceCodeTab() {
      this.$prompt('请输入代码片段描述', '提示', {
        inputPattern: /^.{1,20}$/,
        inputValidator: (value) => {
          if (this.sourceCode.filter(item => item.title === value).length > 0) {
            return '描述已存在'
          }
        },
        inputErrorMessage: '长度最大个20字符'
      }).then(({value}) => {
        const newTabName = ++this.sourceCodeTabIndex + '';
        value = value.trim();
        this.sourceCode.push({
          title: value,
          content: '',
          name: newTabName
        });
        this.editableTabsValue = newTabName
      })
    },
    removeSourceCodeTab(targetName) {
      const tabs = this.sourceCode;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }
      this.editableTabsValue = activeName;
      this.sourceCode = tabs.filter(tab => tab.name !== targetName)
    }
  },
  computed: {
    sourceTypeOptions() {
      let options = [];
      if (this.options) {
        const type = (this.options && this.options.codeType) || [];
        const group = {};
        type.map(item => {
          if (group[item.type]) {
            group[item.type].options.push({
              value: item.reqtypeid,
              label: item.typename
            })
          } else {
            group[item.type] = {
              options: [
                {
                  value: item.reqtypeid,
                  label: item.typename
                }
              ]
            }
          }
        });
        options = group[1].options;
      }
      return options
    },
    codeSizeUnit() {
      return [
        {
          label: 'KB',
          value: 1
        },
        {
          label: 'M',
          value: 1024
        }
      ]
    },
    typeOptions() {
      let options = [];
      let types = (this.options && this.options.type) || [];
      if (types && types.length > 0) {
        options = types.map(type => {
          return {
            label: type.typeName,
            value: type.typeId,
            children: type.children.map(item => {
              return {
                label: item.typeName,
                value: item.typeId,
              }
            })
          }
        })
      }
      return options;
    },
  },
  beforeMount() {
    const {category, codeTypeId, goodsId} = this.getQuery();
    if (!category && !goodsId) {
      this.$router.push('/shop/release/sourcecode');
      return
    }
    if (category) {
      this.goodsForm.typeValue = [category[0].typeId, category[1].typeId];
      this.goodsForm.typeid = category[0].typeId;
      this.goodsForm.subtypeid = category[1].typeId;
    }
    this.getOptions();
    if (codeTypeId) {
      this.goodsForm.codetypeid = codeTypeId;
    }
    if (goodsId) {
      this.goodsId = goodsId;
      this.getGoodsDetail();
    }
  }
}
</script>

<style scoped lang="scss">
.product-edit {
  min-height: 70vh;

  /deep/ {
    .el-card__header .title {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        height: 100%;
        width: 3px;
        background: $main-color;
        top: 0;
        bottom: 0;
        left: -10px;
      }
    }

    .goodsForm .el-form-item:not(.auto) {
      width: 600px;
    }

    .el-form-item {
      .el-input-question {
        position: absolute;
        right: -25px;
        top: 14px;
        cursor: pointer;
      }
    }

    .auxiliaryInputForm {
      line-height: 24px;

      .inner-input {
        width: 50px;

        input {
          padding: 0 5px;
        }
      }

      .el-form-item__content {
        line-height: 24px;
        display: flex;

        > div {
        }
      }
    }
  }
}


.A4 {

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    height: 210px;
    line-height: 210px;
  }


  /deep/ .el-upload--picture-card {
    height: 210px;
    line-height: 210px;
  }

}

.el-select-group .el-select-dropdown__item {
  padding-left: 40px;
}
</style>
